                <template>
                    <div class="home">
                        <!-- <img alt="barcode" src="img/barcode.svg" id="logo"> -->
                        <div class="head">

                            <h1 v-if="term == '' ">Check Price</h1>
                            <h1 v-else>กำลังค้นหา....</h1>

                        </div>

                        <div class="grid">
                            <div> <input type="text" v-model="term" class="search" ref="master">
                                <template v-if="result && term != '' ">
                                    <img class="check" src="img/check.png" v-if="result.length >= 1" style=" height: 30px;"/>
</template>
                            </div>
                            <div><button @click="clear">Clear</button></div>
                        </div>

                        <!-- {{base}} -->
                        <template v-if="result">
                            <h4 v-if="result[0] != undefined">{{result[0].ITEMNAME}}</h4>
                            <h4 v-else>Not Found</h4>
                            <div class="section" v-if="result.length == 1">
                                <div v-for="(item,i) in result">
                                    <p class="price-big"> {{item.PRICE}}</p>
                                  
                                    <!-- {{item.ITEMNAME}} -->
                                </div>
                            </div>
                            <div :class="{'section': true, 'column': true, 'start-col': result.length > 3 } " v-else>
                                <div v-for="(item,i) in result" class="">
                                    <div class="card" v-if="item.PRICE != 0">

                                       <div v-if="item.SYSUNITID > 2 && parseInt((result[0].PRICE - Number(youSave(item.PRICE, findUnit(item.SYSITEMID, item.SYSUNITID).BASEMULTIPLE).toFixed(2)) )  * findUnit(item.SYSITEMID, item.SYSUNITID).BASEMULTIPLE ) > 0" class="yousave">
                                         <span>
                                           ประหยัด <strong>{{ parseInt((result[0].PRICE - Number(youSave(item.PRICE, findUnit(item.SYSITEMID, item.SYSUNITID).BASEMULTIPLE).toFixed(2)) )  * findUnit(item.SYSITEMID, item.SYSUNITID).BASEMULTIPLE ) }}</strong> บาท
                                         </span> 
                                       </div>
                                        <p :class="{'price':true,'thousand': item.PRICE > 999 }" > {{item.PRICE}}</p>
                                        <!-- {{findUnit(item.SYSITEMID).MULTIPLE}} -->
                                          {{ item.PRICE.length}}
                                        <h3> 1 {{unitname[item.SYSUNITID].name}} </h3>

                                          <div class="bottom-tips">

                                         


                                            <div>
                                                <span class="tips"> จำนวน {{findUnit(item.SYSITEMID, item.SYSUNITID).BASEMULTIPLE}} ชิ้น</span>
                                            </div>
                                               <div>
                                                 <template v-if="item.SYSUNITID > 2 ">
                                           <span class="sub-red"> {{youSave(item.PRICE, findUnit(item.SYSITEMID, item.SYSUNITID).BASEMULTIPLE).toFixed(2) }} / ชิ้น </span>
                                        </template>
                                            </div>
                                          </div>
                                     
                                      

                                        <!-- <p class="name"> {{item.ITEMNAME}}</p> -->
                                    </div>

                                </div>
                            </div>

                        </template>
                        <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
                    </div>
                </template>

                <script>
                    // @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import Data from './../data.json'
import UNIT from './../unit.json'
export default {
  name: 'Home',
  components: {
    HelloWorld
  },
  data(){
    return{
      term:'',
      result:null,
      database: Data.data,
      units: UNIT.data,
      unitname: {2:{name: "ชิ้น"},
28:{name: "มัด"},
29:{name: "แถว"},
9:{name: "กล่อง"},
21:{name: "โหล"},
23:{name: "ลัง"},
27:{name: "กระสอบ"},
30:{name: "แพ็ค"},
31:{name: "ลัง"},
32:{name: "แผง"},
33:{name: "ขวด"},
34:{name: "กระปุก"},
35:{name: "SET1"},
37:{name: "กล่อง-2"},
36:{name: "กล่อง-1"},
38:{name: "ลัง-4" }}
}
    
  },
  watch:{

    term(){
      var vm = this
      if(this.term.length > 3 && this.base.length >- 1){
        this.result = this.base.sort(function (a, b) {
  return a.PRICE - b.PRICE;
});

    setTimeout(() => {
        vm.clear()
      },2000)
      }
    }
  },

  computed:{
    base(){
     return Data.data.filter(item => {
         return item.BARCODE.toLowerCase() == this.term.toLowerCase()
      })
    }
  },
  methods:{

    youSave(price, qty){

        return price / qty
    },

    findUnit(g, u){
       return this.units.find(p=>{ return p.SYSITEMID == g && p.SYSUNITID == u})
    },  
    clear(){
      this.term = ''
      // this.refs.master.$el.focus()
      this.$nextTick(() => this.$refs.master.focus())
    },
    timeup(){
      setTimeout(() => {
        this.clear()
      },3000)
    }
  }
}
</script>

                <style type="text/css">
                #logo {
                    height: 60px;

                }

                .grid>div {
                    position: relative;
                }

                .search {
                    height: 40px;
                    width: 75%;
                    border-radius: 10px;
                    border: solid #dddddd;
                    padding: 0px 35px;

                }

                .section {
                    margin-top: 50px;
                    padding: 10px;


                }

                .grid {

                    display: flex;
                    justify-content: center;
                    grid-gap: 10px;
                }

                .home {
                    margin-top: 120px;
                    margin: 8px;

                }

                .price {

                    font-size: 6rem;
                    font-weight: 900;
                    text-align: center;
                    margin-block-start: 0em;
                    margin-block-end: 0em;
                }

                .price-big {
                    font-size: 150pt;
                    font-weight: 900;
                    text-align: center;
                    margin-block-start: 0em;
                    margin-block-end: 0em;
                }

                button {
                    height: 45px;
                    margin-left: 20px;
                    border: solid lightgrey 1px;
                    width: 80px;
                    border-radius: 10px;
                    color: #fff;
                    text-transform: uppercase;
                    background: #2d3e4f;
                    font-weight: 700;
                }

                .head {
                    position: relative;
                    height: 100px;
                }

                img.check {
                    position: absolute;
                    bottom: 8px;
                    left: 6px;

                }

                .name {
                    padding: 15px;
                    text-align: left;

                }

                .card {
                    border: 1px solid #eee;
                    position: relative;
                    margin-left: 25px;
                    border-radius: 8px;
                    box-shadow: black;
                    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
                    transition: 0.3s;
                    box-sizing: border-box;
                    height: 220px;
                    min-width: 230px;
                    background: rgb(235, 235, 237);
                    background: linear-gradient(356deg, rgba(235, 235, 237, 0.9962359943977591) 0%, rgba(255, 255, 255, 1) 50%, rgb(229 238 250) 100%);
                  
                    padding: 0 20px;
                }

                @media only screen and (max-width: 390px) {
                    .column {

                        justify-content: start !important;


                    }

                }

                .start-col{
                  justify-content: start !important;
                }

                input:focus {
                    outline: none;
                    outline-color: black;
                    outline-width: 2px;
                    border: solid #2d3e4f;
                }

                .column {
                    display: flex;
                    justify-content: center;
                    overflow-x: scroll;
                    padding-bottom: 60px;
                }

                .tips {}

                @supports(padding:max(0px)) {

                    body,
                    header,
                    footer {
                        padding-left: min(0vmin, env(safe-area-inset-left));
                        padding-right: min(0vmin, env(safe-area-inset-right));
                        padding-top: min(0vmin, env(safe-area-inset-top));
                        padding-bottom: min(0vmin, env(safe-area-inset-bottom));
                    }
                }

                h3 {
                    margin-block-end: 0.6em;
                }

                .bottom-tips {
                    display: flex;
                    justify-content: space-between;

                }

                .tips {
                    COLOR: #afafaf;
                    font-size: 10pt;
                }

                .sub-red {
    color: #f99696;
    font-size: 10pt;
    font-weight: bold;
}

                .red{
                  color: red;
                   font-size: 10pt;
                   font-weight: bold;
                }

                .yousave{
               
                    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    transform: translate(0%, 50%);
    font-size: 10pt;
    background: red;
    height: 24px;
    top: 100px;
    width: 50%;
    height: 20px;
    color: #fff;
                }

                .thousand{
                  font-size: 5rem;
    margin-block-start: 0.14em;
    margin-block-end: 0.4em;
                }
                

                </style>
